import axios from "axios";
import qs from "qs";
import { presentInLocalStorage } from "../../constants/functions";
import { isEmpty, isNull } from "lodash";

let actions = {
  async getStatuses({ commit }, status) {
    try {
      const res = await axios.get(`/admin/plan-status/search/${status}`);
      const statusesList = res.data.filteredStatus;
      commit("addStatusesList", statusesList);
    } catch (err) {
      console.log(err);
    }
  },
  async getUsersWithAllTags({ commit }) {
    return await axios
      .get("admin/tags/search/active")
      .then(res => {
        commit("changeUsersWithAllTags", res.data.allTagCount);
        commit("changeCustomTagsList", res.data.filteredTags);
      })
      .catch(err => {
        console.log(err);
      });
  },
  async getSearchedTags({ commit }, searchText) {
    return await axios
      .get("admin/tags/search/active", {
        params: {
          tag: searchText,
          limit: 50
        }
      })
      .then(res => {
        commit("changeCustomTagsList", res.data.filteredTags);
      })
      .catch(err => {
        console.log(err);
      });
  },
  async getUsers(
    { commit, state },
    { filter, pagination, search, sort, status, tags, id, section }
  ) {
    commit("isAdminUsersLoading", true);
    const { selectedTagOption: tag } = state.tags;
    const { selectedStatusOption: planStatus } = state.plansMenuItem;
    console.log('jijij')
    const params = {
      filter: !isEmpty(filter) ? filter : null,
      pagination: !isEmpty(pagination) ? pagination : null,
      tag: tag || null,
      statusPlan: planStatus || null,
      search: search && search.length ? search : null,
      columnTag: tags
    };

    const query = qs.stringify(
      Object.keys(params)
        .filter(item => !isNull(params[item]) && item !== "filter")
        .reduce((acc, item) => {
          return Object.assign(
            acc,
            {
              [item]: params[item]
            },
            params.filter,
            sort
          );
        }, {})
    );

    const idRequest = Boolean(id) ? `/${id}/` : "";

    try {
      const res = await axios.get(
        `admin/users/filter/${status}${idRequest}${
          query.length ? "?" + query : ""
        }`
      );
      const data = res.data;
      const { totalUsers, users, accessColumns: columns } = data;
      state.users.usersCount === totalUsers
        ? null
        : commit("addUsersCount", totalUsers);

      if (Boolean(id)) {
        const owner = users.filter(item => item.id === parseInt(id));
        const subUsers = users.filter(item => item.id !== parseInt(id));
        subUsers.unshift(owner);
        commit("addUsersList", subUsers.flat());
      } else {
        commit("addUsersList", users);
      }

      commit("isAdminUsersLoading", false);

      let columnsObjects = columns?.map((col, index) => {
        return {
          id: index + 1,
          name: col
        };
      });

      commit("addDefaultAvailableColumns", {
        columns: columnsObjects,
        section
      });

      if (!presentInLocalStorage(section, "availableColumns") || Boolean(id)) {
        commit("addAvailableColumns", {
          columns: columnsObjects,
          section
        });
        commit("changeAvailableColumnsOrder", section);
        commit("addTemporaryOrder", {
          columns: columnsObjects,
          section
        });
      }
    } catch (err) {
      commit("isAdminUsersLoading", false);
      console.log(err);
    }
  },
  onColumnsDonePushed({ commit }, section) {
    commit("columnsDonePushed", section);
    commit("changeColumnsOrder", section);
    commit("changeAvailableColumnsOrder", section);
  },
  setInitialColumnsOrder({ commit }, section) {
    if (!presentInLocalStorage(section, "checkedColumnsForMenu")) {
      commit("restoreDefaultCheckedColumns", section);
    }
  },
  resetDefaults({ commit }, section) {
    commit("restoreDefaultCheckedColumns", section);
    commit("restoreInitialColumnsOrder", section);
    commit("changeAvailableColumnsOrder", section);
  },
  onColumnsDonePushed({ commit }, section) {
    commit("columnsDonePushed", section);
    commit("changeColumnsOrder", section);
    commit("changeAvailableColumnsOrder", section);
  },
  async getComplianceIssues({ commit }, id) {
    try {
      const res = await axios.get(`/admin/users/compliance/${id}`);
      commit("addComplianceIssues", res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getUserInfo({ commit }, id) {
    try {
      const res = await axios.get(`/admin/users/${id}`);
      const name = res.data.full_name;
      commit("addUserInfo", res.data);
      commit("addUserAccountName", name);
    } catch (err) {
      console.log(err);
    }
  },
  async getUserBillingInfo({ commit }, id) {
    try {
      const res = await axios.get(`/admin/users/${id}/information`);
      commit("setBillingInformation", res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getUserBillingStatus({ commit }, id) {
    try {
      const res = await axios.get(`admin/users/${id}/status`);
      commit("setUserBillingStatus", res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getUserBillingInvoices({ commit }, id) {
    try {
      const res = await axios.get(`admin/users/${id}/invoices`);
      commit("setBillingInvoices", res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getUserBillingEstimate({ commit }, id) {
    try {
      const res = await axios.get(`admin/users/${id}/estimate`);
      commit("setBillingEstimate", res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getUserSubscriptionData({ commit }, id) {
    try {
      const res = await axios.get(`admin/users/${id}/subscription`);
      commit("setUserSubscription", res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getAdminNotes({ commit }, id) {
    try {
      const res = await axios.get(`admin/users/${id}/note`);
      commit("setAdminNotes", res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async addAdminNote({ commit }, { id, val }) {
    try {
      console.log("val, ", val);
      axios.post(`admin/users/${id}/note`, {
        note: val
      });
    } catch (err) {
      console.log(err);
    }
  },
  async deleteAdminNote({ commit }, { userId, noteId }) {
    try {
      return await axios.delete(`admin/users/${userId}/note/${noteId}`);
    } catch (err) {
      console.log(err);
    }
  },
  async addEditedAdminNote({ commit }, { userId, noteId, text }) {
    try {
      return await axios.put(`admin/users/${userId}/note/${noteId}`, {
        note: text
      });
    } catch (err) {
      console.log(err);
    }
  },
  async setUserAction({ commit }, { userId, body }) {
    try {
      return axios.post(`admin/users/compliance/${userId}`, body);
    } catch (err) {
      throw new Error(err);
    }
  },
  async getSuspensionReasons({ commit }) {
    try {
      const res = await axios.get("admin/issues");
      commit("setSuspensionReasons", res.data);
    } catch (err) {
      throw new Error(err);
    }
  },
  async sendNewProfileData({ commit }, { userId, body }) {
    try {
      return axios.put(`admin/users/${userId}`, body);
    } catch (err) {
      throw new Error(err);
    }
  },
  async setBonusBandwidth({ commit }, { userId, bonusSize }) {
    try {
      return await axios.post(`admin/users/${userId}`, {
        bonus_bandwidth: bonusSize
      });
    } catch (err) {
      throw new Error(err);
    }
  }
};

export default actions;
