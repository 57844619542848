<template>
  <div class="all-cards">
    <dashboard-card></dashboard-card>
  </div>
</template>

<script>
import DashboardCard from "./dashboard/DashboardCard.vue";
export default {
  data() {
    return {

    };
  },
  components: {
    DashboardCard,
  },
};
</script>

<style lang="less">
.all-cards{
  position: absolute;
  top: 55px;
}
</style>
