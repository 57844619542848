<template>
    <div>
        <div class="setting sidebar">
          <ul>
            <li> <router-link :to="{name:'WhiteBoardSettingsBranding'}">Branding</router-link>  </li>
            <li> <router-link :to="{name:'WhiteBoardSettingsUserCrm'}">User/CRM Sync</router-link>  </li>
            <li> <router-link :to="{name:'WhiteBoardSettingsEmail'}">Email Settings</router-link>  </li>
            <li> <router-link :to="{name:'WhiteBoardSettingsIntegration'}">Integration</router-link>  </li>
          </ul>
        </div>
    <router-view></router-view>
    </div>
</template>

<script>
export default{
  name: 'WhiteBoardSetting'
}
</script>
<style lang="less" scoped>
  .setting.sidebar{
    width: 150px;
    background: #e5e5d6;
    position: fixed;
    height: 100%;
    color:#174a7c;
    top:118px;
    ul{
      list-style: none;
      padding: 0;
      margin-left: 0;
      margin-top: 20px;
      li{
        padding: 20px 20px;
        cursor: pointer;
      }
    }
  }
</style>
