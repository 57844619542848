<template>
    <div class="brand-setting">
        <el-form>

            <el-form-item label="App Name">
                <el-input class="expand field" v-model="brandDetail.app_name" @keyup.native="storeAppName"></el-input>
            </el-form-item>

            <el-form-item label="App Logo">
                <button data-v-2606308e="" type="button" class="btn btn-default btn-primary-outline">
                    Add Picture
                </button>
            </el-form-item>

            <el-form-item label="App Root Domain">
            </el-form-item>
            <div class="root-domain d-flex">
          <span>
            <el-tooltip :content="'Switch value: ' + form.value" placement="top">
            <el-switch
                    v-model="brandDetail.value"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="100"
                    inactive-value="0">
            </el-switch>

          </el-tooltip>
             <span class="url ml-2">www.babacooking.com</span>
          </span>

                <span class="secure button">
            <button>Secured</button>
          </span>
                <span class="delete button">
            <button>Delete</button>
          </span>

            </div>
            <div class="mt-4 d-flex align-items-center">
                <span class="key-label">Api Key</span>
                <span class="key-code">{{brandDetail.api_key}}</span>
                <span class="reset-key-label" @click="resetApi">Reset Key</span>
            </div>


            <div class="mt-4 link-section">
                <p class="spand"> LOGIN: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis!</p>
                <p class="spand">SIGNUP: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis!</p>
                <p class="spand">RESET PASSWORD:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis!</p>
                <p>CHANGE PASSWORD API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>SUSPENDED USER API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>UNSUSPENDED USER API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>CHANGE PLAN API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>SEE USER PLAN DETAILS API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:https://Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>GET USER PLAN DETAILS API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>GET FAILED USER PLAN DETAILS API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorem, debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>GET CANCELLED USER API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>GET ACTIVE USERS API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>CHANGE EMAIL API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>CREATE ACCOUNT API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
                <p>DELETE ACCOUNT API URL: Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem,
                    debitis! </p>
                <span>eg:Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, debitis! </span>
            </div>


        </el-form>


    </div>
</template>
<script>
    export default {
        data() {
            return {
                form: {
                    name: '',
                    logo: '',
                    value: '100'
                },

                brandDetail: {

                    app_name: '',
                    app_logo: '',
                    api_key: '',
                    app_domain: '',
                    app_domain_status: '',

                },

            };
        },
        components: {},
        created() {
            this.getBrandDetail();
        },
        methods: {
            resetApi() {
                let post_data = {
                    url: "/whitelabel/reserApi",

                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        this.brandDetail.api_key = response.data.detail.api_key
                    })
                    .catch(error => {

                    });
            },
            storeAppName() {
                var $this = this;

                let post_data = {
                    url: "/whitelabel/storeAppName",
                    data: {
                        app_name: $this.brandDetail.app_name
                    }
                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {

                    })
                    .catch(error => {

                    });
            },
            getBrandDetail() {
                let post_data = {
                    url: "/whitelabel/getBrandDetail",
                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        this.brandDetail = response.data.detail
                    })
                    .catch(error => {

                    });
            }
        }
    };
</script>

<style lang="less" scoped>

    .brand-setting {
        left: 150px;
        position: absolute;
        width: calc(~"100% - 150px");
        height: calc(~"100vh - 120px");
        top: 55px;
        padding: 30px;

        form {
            padding: 20px;
            background: #f9fbfb;
        }

        .expand.field {
            display: block;
        }

        .expand.field:first-child {
            max-width: 400px;
        }

        .root-domain {
            background: #fff;
            padding: 12px;
            width: 100%;
            max-width: 700px;
            justify-content: space-between;
            box-shadow: 0px 2px 6px 0px #00000026;
            border-radius: 6px;
            align-items: center;

            .secure.button button {
                color: #00acdc;
                background: #ebfaec;
                padding: 8px 12px;
                border: none;
                border-radius: 4px;
            }

            .delete.button button {
                color: #fff;
                background: #f5bbbb;
                padding: 9px 20px;
                border: none;
                border-radius: 30px;
            }

        }

        .key-code {
            background: #ebf9fa;
            border: 1px dashed #809db9;
            font-size: 20px;
            height: 50px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            border-radius: 10px;
        }

        .key-label {
            font-weight: bold;
            padding-right: 20px;
        }

        .reset-key-label {
            font-weight: bold;
            padding-left: 20px;
            color: #d93636;
            cursor: pointer;
        }

        .link-section {
            background: #fff;
            border-radius: 4px;
            max-width: 800px;
            padding: 20px;
            box-shadow: 0px 2px 6px 0px #0000000a;

            p {
                color: #2c3e50cf;
                margin-bottom: 0;
            }

            span, .spand {
                margin-bottom: 20px !important;
                display: block;
            }
        }
    }
</style>
