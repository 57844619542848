import Ls from "../../utils/Ls";
import BCookie from "../../utils/BCookie";
import admin_mutations from "../admin/mutations";
import project_mutations from '../projects/mutations';
import plan_mutations from '../plans/mutations';
import collaboration_mutations from '../collaborations/mutations';

let mutations = {
    /**
     * Set Authenticated Access Token.
     *
     * @param state
     * @param token
     * @param expires
     */
    setAccessToken: (state, {token, expires}) => {
        BCookie.set("BCAccessToken", token, expires);
        
        state.accessToken = token;
    },
    
    /**
     * Change Site Page Title.
     *
     * @param state
     * @param title
     */
    changePageTitle(state, title) {
        state.pageTitle = title;
        document.title = title + " | " + state.siteName
    },
    
    /**
     * Set User Account Information.
     *
     * @param state
     * @param user
     */
    setUserInfo(state, user) {
        state.userData = user;
    },

    /**
     * Set User Subscription Information.
     *
     * @param state
     * @param subscription
     */
    setUserSubscription(state, subscription) {
        state.subscription = subscription;
    },

    /**
     * Set Subscription plans Information.
     *
     * @param state
     * @param subscriptionPlans
     */
    setSubscriptionPlans(state, subscriptionPlans) {
        state.subscriptionPlans = subscriptionPlans;
    },

    /**
     * Set User Billing Status Information.
     *
     * @param state
     * @param billingStatus
     */
    setUserBillingStatus(state, billingStatus) {
        state.billingStatus = billingStatus;
    },

    /**
     * Set User Usage Information.
     *
     * @param state
     * @param usageData
     */
    setUserUsage(state, usageData) {
        state.usageData = usageData;
    },

    /**
     * Set billingEstimate.
     *
     * @param state
     * @param billingEstimate
     */
    setBillingEstimate(state, billingEstimate) {
        state.billingEstimate = billingEstimate;
    },

    /**
     * Set billingInformation.
     *
     * @param state
     * @param billingInformation
     */
    setBillingInformation(state, billingInformation) {
        state.billingInformation = billingInformation;
    },

    /**
     * Set billingInvoices.
     *
     * @param state
     * @param billingInvoices
     */
    setBillingInvoices(state, billingInvoices) {
        state.billingInvoices = billingInvoices;
    },

    /**
     * Set invoiceDetails.
     *
     * @param state
     * @param invoiceDetails
     */
    setInvoiceDetails(state, invoiceDetails) {
        state.invoiceDetails = invoiceDetails;
    },

    /**
     * Set isMobile.
     *
     * @param state
     * @param isMobile
     */
    setIsMobile(state, isMobile) {
        state.isMobile = isMobile;
    },
    
    /**
     * User log out.
     * @param state
     */
    destroyAccessToken(state) {
        BCookie.remove("BCAccessToken");
        
        Ls.remove("BC-Auth-Remember");
        
        state.userData = null;
        state.accessToken = null;
    }
};

export default Object.assign(
    mutations,
    admin_mutations,
    project_mutations,
    plan_mutations,
    collaboration_mutations,
);
