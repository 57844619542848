<template>
    <div class="topbar" id="top-bar" v-if="user && showMenu">
        <div id="toggle" @click="select()">
            <div class="span" id="top" :class="{ active: isActiveMainMenu }"></div>
            <div class="span" id="middle" :class="{ active: isActiveMainMenu }"></div>
            <div class="span" id="bottom" :class="{ active: isActiveMainMenu }"></div>
        </div>
        <div id="main_menu" :class="{active: isActiveMainMenu}">
            <el-menu
                    :router="true"
                    @select="handleSelect"
                    class="el-menu-main"
                    mode="horizontal">

                <div class="left">
                    <el-menu-item :key="1" class="logo-item" index="/" style="padding-left:20px;">
                        <router-link to="/" class="logo-link"><img alt="Adilo" class="logo" src="../../assets/4kplayerlogo.svg"></router-link>
                    </el-menu-item>
                </div>
                <div v-if="isMobile" class="logo-img">
                    <router-link to="/"><img alt="Adilo" class="logo" src="../../assets/4kplayerlogo.svg"></router-link>
                </div>
                <div class="center" :class="{active: isActiveMainMenu}" v-if="activeTabName !== null">
<!--                    <el-menu-item class="main-menu-item" :class="{'is-active': projectActive}" :to="{path: '/projects'}" index="/all-project">-->
<!--                        <a href="/all-project" @click.prevent>Projects</a>-->
<!--                    </el-menu-item>-->
<!--                    <el-menu-item class="main-menu-item" :class="{'is-active': activeTabName === 'white-label'}" :to="{path: '/white-label/dash-board'}" index="/white-label/dash-board">-->
<!--                        <a href="/white-label/dash-board" @click.prevent>4kPlayer</a>-->
<!--                    </el-menu-item>-->
                    <el-menu-item class="main-menu-item" :class="{'is-active': activeTabName === 'power-player'}" :to="{path: '/power-player'}" index="/power-player">
                        <a href="/power-player" @click.prevent>My Videos</a>
                    </el-menu-item>
<!--                    <el-menu-item class="main-menu-item" :class="{'is-active': (activeTabName === 'snaps')}" :to="{path: '/snaps'}" index="/snaps">-->
<!--                        <a href="/snaps" @click.prevent>SnapByte</a>-->
<!--                    </el-menu-item>-->
                    <!-- <el-menu-item class="main-menu-item" :class="{'is-active': (activeTabName === 'rooms')}" :to="{path: '/rooms'}" index="/rooms">
                        <a href="/rooms" @click.prevent>Rooms</a>
                    </el-menu-item> -->
<!--                    <el-menu-item class="main-menu-item" :class="{'is-active': (activeTabName === 'stage')}" :to="{path: '/stage'}" index="/stage">-->
<!--                        <a href="/stage" @click.prevent>Stage</a>-->
<!--                    </el-menu-item>-->
<!--                    <el-menu-item class="main-menu-item" :class="{'is-active': (activeTabName === 'contacts')}" :to="{path: '/contacts'}" index="/contacts">-->
<!--                        <a href="/contacts" @click.prevent>Contacts</a>-->
<!--                    </el-menu-item>-->
                    <el-menu-item class="main-menu-item" :class="{'is-active': (activeTabName === 'analytics')}" :to="{path: '/analytics'}" index="/analytics">
                        <a href="/analytics" @click.prevent>Analytics</a>
                    </el-menu-item>
                    <el-menu-item class="main-menu-item" :class="{'is-active': (activeTabName === 'settings')}" :to="{path: '/settings'}" index="/settings">
                        <a href="/settings" @click.prevent>Settings</a>
                    </el-menu-item>
                    <el-menu-item class="main-menu-item" :class="{'is-active': (activeTabName === 'help')}" index="/">
                        <a href="https://help.bigcommand.com" target='_blank'>Help</a>
                    </el-menu-item>
                </div>

                <div class="right">
                    <el-submenu class="profile-menu-item sub-menu" index="">
                        <template slot="title">
                            <div class="user-info">
                                <div class="user-name">{{user.full_name}}</div>
                                <div class="user-company text-capitalize">{{user.settings.company}}</div>
                            </div>
                            <img :src="user.settings.avatar"
                                 alt=""
                                 class="img-circle profile-avatar"
                                 v-if="user.settings"
                                 width="42">
                        </template>

                        <router-link to="/settings">
                            <el-menu-item class="text-center" index="settings" style="height: auto">
                                <img :src="croppa(user.settings.avatar, 150, 150)" alt="" class="img-circle row-space-top-2" height="60" width="60">
                                <div class="user-identifire">
                                    <span>{{ user.full_name }}</span>
                                </div>
                            </el-menu-item>
                        </router-link>

<!--                        <router-link to="/contacts">-->
<!--                            <el-menu-item index="contacts">-->
<!--                                <span>Subscribers</span>-->
<!--                            </el-menu-item>-->
<!--                        </router-link>-->
<!--                        <router-link to="/settings/user_referral">-->
<!--                            <el-menu-item index="contacts">-->
<!--                                <span>Referral Earnings</span>-->
<!--                            </el-menu-item>-->
<!--                        </router-link>-->

                        <router-link to="/logout">
                            <el-menu-item index="logout">
                                <span>Logout</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                </div>
            </el-menu>
            <div class="hamb"></div>
        </div>
        <div class="failed-message" v-if="user.billing_status=='Failed'"><p>Your account renewal failed. Please update the payment method on file and pay invoice <el-link @click="openInvoicePage(user.failed_payment.invoice)">{{user.failed_payment.invoice.receipt_id}}</el-link>  to avoid service disruption on {{user.failed_payment.cancel_date}}.</p></div>
    </div>
</template>
<style lang="less" src="../../assets/less/custom/app-header.less"></style>
<script>
    import $ from 'jquery';
    export default {
        name: "app-header",
        props: {
            user: Object
        },
        data: () => ({
            notifications: [],
            notificationsLoading: true,
            index: 0,
            searchQuery: "",
            currentTab: "/",
            activeTabName: "dashboard",
            isActiveMainMenu: false,
            isMobile: false,
            blockedRoutes: ['RoomPage', 'SnapSharedRecording', 'SnapPageView', 'PublicVideo', 'SubscriptionCheckout'],
            showMenu: true
        }),

        created() {
            this.currentTab = window.location.pathname;
            this.mobileCheck();
            let route = this.$route.name;
            this.publicUrl(route);
           
        },

        computed: {
            projectActive() {
                return (
                    this.currentTab.indexOf("projects") !== -1
                );
            },

            searchActive() {
                return false;
            }
        },

        beforeDestroy() {
            window.vEvent.stop("app-header-active-select", this.handleSelect);
        },

        mounted() {
           
            window.vEvent.listen("app-header-active-select", this.handleSelect);

            this.selectedTab();
        },

        methods: {
            selectedTab() {
                if (this.currentTab === '/') {
                    this.activeTabName = "dashboard";
                } else {
                    if (this.currentTab.indexOf("stage") > -1 && this.currentTab.indexOf("settings") === -1) {
                        this.activeTabName = "stage";
                    } else if (this.currentTab.indexOf("contacts") > -1) {
                        this.activeTabName = "contacts";
                    } else if (this.currentTab.indexOf("analytics") > -1) {
                        this.activeTabName = "analytics";
                    } else if (this.currentTab.indexOf("settings") > -1) {
                        this.activeTabName = "settings";
                    } else if (this.currentTab === "/help") {
                        this.activeTabName = "help";
                    } else {
                        this.activeTabName = "project";
                    }
                }
            },

            handleSearchOpen() {
                this.$emit("openSearch");
            },

            handleSelect(index) {
                this.currentTab = index;

                if (index === "/contacts") {
                    window.vEvent.fire("go-contacts-list-page");
                }

                this.activeTabName = null;

                this.$nextTick(() => {
                    this.selectedTab();
                }); 
            },

            select: function () {
                this.isActiveMainMenu = !this.isActiveMainMenu;
            },

            mobileCheck() {
                let BC_onMobile = false;
                if (window.screen.availWidth <= 960 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }

                this.isMobile = BC_onMobile;
            },
                openInvoicePage(invoice) {
                    let path = ''
                        path = {
                            name: 'BillingReceipt',
                            params: { section: 'billing', receiptID: invoice.id }
                        };
                   
                    this.$store.commit('setInvoiceDetails', invoice);
                    this.$router.push(path);
                },
            publicUrl(currentRoute)
            {
                if (this.blockedRoutes.includes(currentRoute)) {
                    if (currentRoute == 'SnapPageView') {
                        setTimeout(() => {
                            if (this.$store !== undefined && this.$store.getters.isAuthenticated != null) {
                                this.showMenu = true;
                            } else {
                                this.showMenu = false;
                            }
                        }, 1000);
                    } else {
                        this.showMenu = false;
                    }
                } else {
                    this.showMenu = true;
                }
            }
            
        },
        components: {},
        watch:{
            // $route (to, from){
            //     this.publicUrl(to.name);
            // }
        } 
    };
</script>
