<template>
  <div class="">
    <el-aside width="200px" class="k-el-asid">
      <ul>
        <li>
          <router-link :to="{ name: 'PowerPlayer' }"
            ><img src="../../../assets/img/4kplayer/4k-logo.png" width="100px"
          /></router-link>
        </li>
      </ul>
    </el-aside>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>

<style lang="less">
.el-aside.k-el-asid {
  background: #18242f;
  height: 100%;
  margin-left: 0;
  padding-top: 60px;
  position: fixed;
  @media(max-width: 767px){
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    img{
      width: 70%;
    }

  }
}



</style>
