<template>
  <el-container class="main-container">
   <div>
     <the-sidebar></the-sidebar>

     <el-container class="right-side">
       <the-header></the-header>
       <!-- <the-sidebar></the-sidebar> -->
       <!-- <el-main> </el-main> -->
       <div class="main-content">
         <router-view></router-view>
       </div>
     </el-container>
   </div>
  </el-container>
</template>

<script>
import TheHeader from "../components/4kplayer/Items/TheHeader";
import TheSidebar from "../components/4kplayer/Items/TheSidebar";
export default {
  data() {
    return {};
  },
  components: {
    TheHeader,
    TheSidebar,
  },
};
</script>
<style lang="less" scoped>

.main-container {
  display: flex;
}

.right-side {
  left: 200px;
  position: absolute;
  width: calc(~"100% - 200px");
  @media(max-width: 767px){
    left: 0;
    width: 100%;
  }
  .header-container{
    position: fixed;
    width: calc(~"100% - 200px");
    z-index: 1;
    @media(max-width: 767px){
      width: 100%;
    }
    ul{
      li{
        @media(max-width: 767px) {
          margin-left: 0;
        }
      }
    }

  }
  .main-content{
    //padding: 30px 70px;

  }
}
</style>
