import jQuery from 'jquery';
export default {
  getTruncated(originText, lines, style, textAppend = '') {
    if (!originText) {
      return '';
    }
    let textTruncated = '';
    const height = parseInt(style['line-height']) * lines;
    let inlineStyle = 'position: fixed; top: 200px; left: 100px;';
    for (let prop in style) {
      inlineStyle += prop + ': ' + style[prop] + ';';
    }
    let dom = jQuery('<div style="' + inlineStyle + '"></div>').appendTo('body');
    for (let i = 0; i < originText.length; i ++) {
      textTruncated += originText[i];
      dom.html(textTruncated + textAppend);
      if (dom.height() > height) {
        textTruncated = textTruncated.substr(0, textTruncated.length - 1);
        break;
      }
    }
    dom.remove();
    return textTruncated;
  },
  shuffle(text) {
    let a = text.split(""),
      n = a.length;

    for(let i = n - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let tmp = a[i];
      a[i] = a[j];
      a[j] = tmp;
    }
    return a.join("");
  },
  getThumbnailFromVideoFile(videoFile) {
    const url = URL.createObjectURL(videoFile);
    const domCanvas = jQuery('<canvas></canvas>');
    const canvas = domCanvas.get(0);
    const domVideo = jQuery('<video autoplay><source src="' + url + '" /></video>');
    const video = domVideo.get(0);
    return new Promise((resolve) => {
      domVideo.on('loadeddata', () => {
        console.log(video.duration);
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas.toDataURL());
        domVideo.remove();
        domCanvas.remove();
      });
    });
  },
  getMetaFromVideoFile(videoFile) {
    const url = URL.createObjectURL(videoFile);
    const domCanvas = jQuery('<canvas></canvas>');
    const canvas = domCanvas.get(0);
    const domVideo = jQuery('<video autoplay><source src="' + url + '" /></video>');
    const video = domVideo.get(0);
    return new Promise((resolve) => {
      domVideo.on('loadeddata', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve({
          thumbnail: canvas.toDataURL(),
          duration: video.duration,
        });
        domVideo.remove();
        domCanvas.remove();
      });
    });
  },
  getNameFromFileName(filename) {
    let arr = filename.split('.');
    arr.pop();
    return arr.join('.');
  },
  getFormatDate(date) {
    const m = date;
    return m.getUTCFullYear() + "-" +
      ("0" + (m.getUTCMonth()+1)).slice(-2) + "-" +
      ("0" + m.getUTCDate()).slice(-2) + " " +
      ("0" + m.getUTCHours()).slice(-2) + ":" +
      ("0" + m.getUTCMinutes()).slice(-2) + ":" +
      ("0" + m.getUTCSeconds()).slice(-2);
  },
  secondsToMMSS(seconds) {
    seconds = Math.round(seconds);
    if (Math.floor(seconds / 60)) {}
    let mm = Math.floor(seconds / 60) % 60;
    let ss = seconds % 60;
    mm = mm >= 10 ? mm : '0' + mm;
    ss = ss >= 10 ? ss : '0' + ss;
    return mm + ':' + ss;
  },
  convertSecondsToTime(given_seconds){
    let dateObj = new Date(given_seconds * 1000),
        hours = dateObj.getUTCHours(),
        minutes = dateObj.getUTCMinutes(),
        seconds = dateObj.getSeconds();

    return hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0');
  },
  convertTimeToSeconds(time = '')
  {
    let timeArr = time.split(/\:|\./ ).reverse();
    let secconds = 0;
    timeArr[0] ? secconds += +timeArr[0] : null;
    timeArr[1] ? secconds += (+timeArr[1] * 60) : null;
    timeArr[2] ? secconds += (+timeArr[2] * 60 * 60) : null;
console.log(time, secconds)
    return secconds;
  }
}
