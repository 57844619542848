<template>
    <div class="users-main">
        <div class="user-header">
            <div class="left-side">
                <div class="title">
                    Create New Users
                </div>
            </div>

        </div>


        <div class="relative mb-3">

            <el-row :gutter="40" class="m-top-3">
                <el-col :span="8" :xs="24">
                    <div class="branding-logo-position">
                        <div class="positions title">
                            <span class="textUppercase">Select plan</span>
                        </div>
                        <div class="positions">
                            <el-select
                                    popper-class="custom_select_pop with_arrow_190"
                                    placeholder="Select"
                                    v-model="plan">
                                <el-option v-for="item in this.plans" :key="item.id"
                                           :label="item.plan_name" :value="item.id"></el-option>
                            </el-select>

                        </div>
                        <span class="error" v-if="validation.type === 'plan'">
                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        {{validation.message}}</span>
                    </div>

                    <div class="branding-logo-position">
                        <div class="positions title">
                            <span class="textUppercase">First Name</span>
                        </div>
                        <div class="positions">
                            <input
                                    class="form-control with_arrow_190"
                                    id="user-first-name"
                                    placeholder="Enter first name"
                                    type="text"
                                    v-model="first_name"
                            />

                        </div>
                        <span class="error" v-if="validation.type === 'first_name'">
                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        {{validation.message}}</span>
                    </div>


                    <div class="branding-logo-position">
                        <div class="positions title">
                            <span class="textUppercase">Last Name</span>
                        </div>
                        <div class="positions">
                            <input
                                    class="form-control with_arrow_190"
                                    id="user-last-name"
                                    placeholder="Enter first name"
                                    type="text"
                                    v-model="last_name"
                            />

                        </div>
                        <span class="error" v-if="validation.type === 'last_name'">
                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        {{validation.message}}</span>
                    </div>


                    <div class="branding-logo-position">
                        <div class="positions title">
                            <span class="textUppercase">Email Address</span>
                        </div>
                        <div class="positions">
                            <input
                                    class="form-control with_arrow_190"
                                    id="user-email-name"
                                    placeholder="Enter first name"
                                    type="text"
                                    v-model="email"
                            /><br>

                        </div>
                        <span class="error" v-if="validation.type === 'email'">
                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        {{validation.message}}</span>
                    </div>


                    <div class="branding-logo-position">
                        <div class="positions title">
                            <span class="textUppercase">Password</span>
                        </div>
                        <div class="positions">
                            <input
                                    @input="passwordValidation"
                                    class="form-control"
                                    placeholder="Enter account password"
                                    required
                                    type="text"
                                    v-model="password"
                            />
                        </div>
                        <span class="error" v-if="validation.type === 'password'">
                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        {{validation.message}}</span>
                    </div>
                </el-col>
            </el-row>

        </div>
        <el-button
                type="custom"
                class="button__cancle"
                @click="$router.push('/white-label/users')"
        >Cancel
        </el-button>
        <el-button
                type="custom"
                class="button__upload"
                @click="saveUser"
        >Add User
        </el-button>

    </div>
</template>


<script>
    import SearchQuery from "../../components/Project/AllProjects/Components/SearchQuery";
    import AuthEmailField from "../Auth/Items/AuthEmailField.vue";
    import PasswordChecker from "./PasswordChecker.vue";

    export default {
        name: 'WhiteBoardCreateUsers',
        components: {},
        data() {
            return {
                id: 0,
                first_name: '',
                last_name: '',
                email: '',
                password_type: "2",
                showPasswordText: false,
                password: "",
                plan: '',
                validation: {
                    message: '',
                    type: '',
                },
                plans: {},

            };
        },
        computed: {
            errorsForm: {
                get() {
                    return this.$store.state.errorsForm;
                }
            }
        },
        created(){
            this.getPlans();
        },
        methods: {

            getPlans() {
                let post_data = {
                    url: "/whitelabel/plans",
                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                     this.plans = response.data.plans;
                    })
                    .catch(error => {

                    });
            },

            validateName(name) {
                let re = /^[a-zA-Z]+$/;
                return re.test(String(name).toLowerCase());
            },
            validateEmail() {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(this.email).toLowerCase());
            },
            passwordValidation() {
                // window.vEvent.fire("password-validation", this.password);

                let re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
                return re.test(String(this.password));
            },


            indexMethod(index) {
                return index * 2;
            },
            clearValidate() {
                this.errorsForm.firstNameRequired = false;
                this.errorsForm.nameInvalid = false;
                this.errorsForm.emailInvalid = false;
                this.errorsForm.emailExist = false;
                this.errorsForm.bemailEmpty = false;
                this.errorsForm.passwordInvalid = false;
                this.errorsForm.passwordType = {
                    uppercase: false,
                    lowercase: false,
                    number: false,
                    special: false,
                    minimum: false
                };
            },
            saveUser() {
                if (this.plan === '') {
                    this.validation.message = 'Please select plan';
                    this.validation.type = 'plan';

                    return false;
                }else if (this.first_name === '') {
                    this.validation.message = 'Please enter first name';
                    this.validation.type = 'first_name';

                    return false;
                } else if (this.last_name === '') {
                    this.validation.message = 'Please enter last name';
                    this.validation.type = 'last_name';

                    return false;
                } else if (this.email === '') {
                    this.validation.message = 'Please enter email';
                    this.validation.type = 'email';

                    return false;
                } else if (!this.validateEmail()) {
                    this.validation.message = 'Please enter valid email';
                    this.validation.type = 'email';

                    return false;
                } else if (this.password === '') {
                    this.validation.message = 'Please enter password';
                    this.validation.type = 'password';

                    return false;
                } else if (!this.passwordValidation()) {
                    this.validation.message = 'Please enter valid and strong password';
                    this.validation.type = 'password';
                    return  false;
                } else {
                    this.validation.message = '';
                    this.validation.type = '';
                }


                let post_data = {
                    url: "/whitelabel/user/store",
                    data: {
                        id: this.id,
                        name: `${this.first_name} ${this.last_name}`,
                        email: this.email,
                        password: this.password,
                        plan_id: this.plan,
                    }
                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        if (response.data.result === "success") {
                            // $router.push('/white-label/users')
                            this.$router.push({name: 'WhiteBoardUsers'})
                            this.validation.message = '';
                            this.validation.type = '';
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (error.response.data.errors) {
                                if (error.response.data.errors.email) {
                                    this.validation.message = error.response.data.errors.email[0];
                                    this.validation.type = 'email';
                                }
                                else if (error.response.data.errors.name) {
                                    this.validation.message = error.response.data.errors.name[0];
                                    this.validation.type = 'first_name';
                                }
                                else if (error.response.data.errors.password) {
                                    this.validation.message = error.response.data.errors.password[0];
                                    this.validation.type = 'password';
                                }else{
                                    this.validation.message = '';
                                    this.validation.type = '';
                                }
                            }
                        }
                    });
            },
        },

    };
</script>

<style lang="less">
    .error {
        color: red;
    }
.button__cancle{
    padding: 10px 25px 9px;
    background-color: #fff;
    border: 1px solid #0044b1;
    font-size: 16px;
    line-height: 19px;
    color: #0044b1;
    border-radius: 5px;
}
    .button__upload {
        padding: 10px 25px 9px;
        background-color: #0044b1;
        border: none;
        font-size: 16px;
        line-height: 19px;
        color: white;
        border-radius: 5px;
    }
    .users-main {
        padding: 40px;
        overflow: auto;
        top: 55px;
        position: absolute;
        width: 100%;
    }

    .positions {
        .el-select {
            width: 100%;
        }
    }

    .user-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .left-side {
            .title {
                font-weight: bold;
                font-size: 22px;
            }

            .total {
                margin-left: 15px;
                font-weight: normal;
            }
        }

        .el-button--custom {
            font-weight: bolder;
        }

        .right-side {
            display: flex;
            align-items: center;

            .text-uppercase {
                font-weight: 700;
            }

            div {
                margin-right: 20px;
            }


        }


    }

    .branding-logo-position {
        margin-top: 30px;
    }

    .user-list-table {
        min-width: 900px;

        p {
            margin-bottom: 5px;
        }

        .table-header {
            display: flex;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .contact-row {
            width: 19%;

            p.name {
                font-weight: bold;
            }

            p {
                margin-bottom: 5px;
                color: #2c3e50;
            }
        }

        .status-row {
            width: 10%
        }

        .plan-row {
            width: 10%;
        }

        .joined-row {
            width: 10%;
        }

        .last-activity-row {
            width: 17%;
        }

        .asset-row {
            width: 17%;
        }

        .table-body {
            .plan-row, .joined-row, .last-activity-row, .asset-row {
                color: #485050cf;
            }
        }

        .action-row {
            width: 17%;
            display: inline-grid;

            button {
                background: none;
                border: 0px;
                text-align: left;
            }
        }

        .table-row {
            display: flex;
            padding-bottom: 30px;

            .actions {
                display: block;
            }
        }
    }

    .color-red {
        color: #d93636;
    }

    .color-pink {
        color: #ea00d8;
    }

    .color-green {
        color: #0bd603;
    }

    .color-blue {
        color: #0bacdb;
    }

    .textUppercase {
        text-transform: uppercase;
    }
</style>

