<template>
    <div class="setting-user-crm">
        
          <div>
            <div>
              <div class="email-head d-flex">
                <h3 class="head-title mr-3">Aweber <span>(hello@4kplayer.com)</span></h3>
                <el-button type="primary" plain>Change CRM</el-button>
              </div>

              <p class="head-title mr-3">On Sign Up:</p>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="ADD/UPADATE TO LIST" class="mb-0">
                  </el-form-item>
                  <el-select  v-model="ruleForm.name" placeholder="Activity zone">
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="ADD TAG" class="mb-0">
                  </el-form-item>

                  <el-select
                      v-model="value"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      placeholder="Choose tags for your article">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>


              <div class="plan-tags">
                <el-form-item label="/IF: PLAN TAGS" class="mb-0">
                </el-form-item>

                <div class="d-flex">
                <el-form-item label="Free:">
                </el-form-item>
                <el-select
                    v-model="value"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="Choose tags for your article">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                </div>



                <div class="d-flex">
                  <el-form-item label="Starter:">
                  </el-form-item>
                  <el-input></el-input>
                </div>
                <div class="d-flex">
                  <el-form-item label="Pro:">
                  </el-form-item>
                  <el-input></el-input>
                </div>


              </div>


              <p class="head-title mr-3">On Cancellation</p>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="ADD/UPADATE TO LIST" class="mb-0">
                  </el-form-item>
                  <el-select  v-model="ruleForm.name" placeholder="Activity zone">
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="ADD TAG" class="mb-0">
                  </el-form-item>

                  <el-select
                      v-model="value"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      placeholder="Choose tags for your article">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>



              <p class="head-title mr-3">On Ban/Suspension</p>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="ADD/UPADATE TO LIST" class="mb-0">
                  </el-form-item>
                  <el-select  v-model="ruleForm.name" placeholder="Activity zone">
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="ADD TAG" class="mb-0">
                  </el-form-item>

                  <el-select
                      v-model="value"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      placeholder="Choose tags for your article">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>


              <p class="head-title mr-3">On Delete</p>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="ADD/UPADATE TO LIST" class="mb-0">
                  </el-form-item>
                  <el-select  v-model="ruleForm.name" placeholder="Activity zone">
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="ADD TAG" class="mb-0">
                  </el-form-item>

                  <el-select
                      v-model="value"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      placeholder="Choose tags for your article">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>



            </div>
          </div>
        
      </div>
</template>


<script>
export default {
   name: 'WhiteBoardSettingsUserCrm',
    data() {
        return {
          ruleForm: {
            name: '',
            desc: ''
          },
          options: [{
            value: 'HTML',
            label: 'HTML'
          }, {
            value: 'CSS',
            label: 'CSS'
          }, {
            value: 'JavaScript',
            label: 'JavaScript'
          }],
          value: []
        }
    },
}
</script>

<style lang="less" scoped>
//.el-input__icon{
//  width:50px;
//  font-size: 30px;
//  font-weight: 100;
//}
.setting-user-crm{
  left: 150px;
  position: absolute;
  width: calc(~"100% - 150px");
  height: calc(~"100vh - 120px");
  top:55px;
  padding: 30px;
  form {
    padding: 20px;
    background: #f9fbfb;

    > div{
      max-width: 1024px;
      margin-bottom: 50px;
    }
    .el-select{
      min-width: 400px;
    }
    .el-input{
      max-width: 400px;
    }
  }
  .mb-0{
    margin-bottom: 0;
  }
    .email-head {
      align-items: center;
      width: 100%;
    }
    .email-setting form > div {
      max-width: 1050px;
    }
    .el-button--primary.is-plain {
      background: #ebf9fa;
      border-color: #33acdb;
      border-radius: 40px;
      color: #406e97;
      height: 45px;
    }
  .plan-tags{
    max-width: 700px;
    margin: 30px auto 0;
  }
.d-flex{
  .el-form-item{
    min-width: 80px;
  }

}
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 25px 20px 0;
    color: #0c4077;
  }
  p.head-title {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 25px 20px 0;
    color: #0c4077;
  }
}
</style>
