<template>
  <div class="card-container">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardDatas[0].cardNumber }}</p>
          <p>of {{ cardDatas[0].totalNumber }} users</p>
          <!-- <el-progress
            :text-inside="true"
            :show-text="false"
            :percentage="userPercentage"
          ></el-progress> -->
          <div class="el-progress-bar">
            <div class="el-progress-bar__outer">
              <div
                class="el-progress-bar__inner"
                :style="{ width: userPercentage }"
              ></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardDatas[1].cardNumber }}</p>
          <p>of {{ cardDatas[1].totalNumber }} languages</p>

          <div class="el-progress-bar">
            <div class="el-progress-bar__outer" style="height: 6px">
              <div
                class="el-progress-bar__inner"
                :style="{ width: lanPercentage }"
              ></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="grid-content text-center text-uppercase upgrade-plan">
          <p class="">upgrade your whitelabel</p>
        </div>
      </el-col>
      <!-- </el-row>
    <el-row :gutter="20"> -->
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardDatas[3].cardNumber }}</p>
          <p>videos</p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardDatas[4].cardNumber }}</p>
          <p>impression</p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <div class="grid-content text-center text-uppercase">
          <p>{{ cardDatas[5].cardNumber }}</p>
          <p>views</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardDatas: [
        {
          cardNumber: 3295,
          text: "of",
          totalNumber: 5000,
          otherMessage: "users",
        },

        {
          cardNumber: 3,
          text: "of",
          totalNumber: 5,
          otherMessage: "language",
        },

        {
          cardNumber: null,
          text: "UPGRADE YOUR WHITELABEL",
          totalNumber: null,
          otherMessage: null,
        },

        {
          cardNumber: 50093,
          text: "videos",
          totalNumber: null,
          otherMessage: null,
        },
        {
          cardNumber: 50093,
          text: "Impression",
          totalNumber: null,
          otherMessage: null,
        },
        {
          cardNumber: 3293940,
          text: "views",
          totalNumber: null,
          otherMessage: null,
        },
      ],
    };
  },
  computed: {
    userPercentage() {
      return (
        Math.floor(
          (this.cardDatas[0].cardNumber / this.cardDatas[0].totalNumber) * 100
        ) + "%"
      );
    },
    lanPercentage() {
      return (
        Math.floor(
          (this.cardDatas[1].cardNumber / this.cardDatas[1].totalNumber) * 100
        ) + "%"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.card-container {
  padding: 50px 30px;
  .el-row{
    margin: 0 !important;
    max-width: 1400px;
  }
  .grid-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #6ec1ab;
    border-radius: 7px;
    margin-bottom: 70px;
    padding: 15px;
    min-height: 170px;
    max-width: 420px;
    p {
      font-size: 22px;
      color: #fff;
    }
    p:first-child {
      font-size: 60px;
      font-weight: bold;
      color: #fff;
    }
    .el-progress-bar {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0;
    }
    .el-progress-bar__outer {
      background: #0bd603;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 50px;
      height: 15px !important;
    }
    .el-progress-bar__inner {
      background: #d93636;
      border-radius: 0;
    }
  }
      .upgrade-plan {
        border-radius: 5px;
        background-image: linear-gradient(114deg, #094077 0%, #d93636 100%);
        p{
          font-size: 35px !important;
        }
      }
}

@media (max-width: 1440px) {
  .card-container {
        min-height: 150px;

    .grid-content.upgrade-plan{
        p{
          font-size: 22px !important;

        }


    }
  }
}

@media (max-width: 960px) {
  .card-container {
    min-height: 200px;

    .grid-content.upgrade-plan{
        p{
          font-size: 20px !important;

        }


    }
  }
}

</style>
