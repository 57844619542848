let mutations = {
  changeSortOption: (state, newOption) => {
    state.selectedSortOption.value = newOption;
  },
  changeSortOrderOption: (state, newOrder) => {
    state.selectedSortOption.order = newOrder;
  },
  changeSortLabel: (state, newLabel) => {
    state.selectedSortOption.label = newLabel;
  },
  changeTagOption: (state, newOption = null) => {
    state.tags.selectedTagOption = newOption;
  },
  changeStatusOption: (state, newOption) => {
    state.selectedStatusOption = newOption;
  },
  changeUsersWithAllTags: (state, value) => {
    state.tags.usersWithAllTags = value;
  },
  changeCustomTagsList: (state, value) => {
    state.tags.customTagsList = value;
  },
  addUsersCount: (state, value) => {
    state.users.usersCount = value;
  },
  addUsersList: (state, value) => {
    state.users.usersList = value;
  },
  addAvailableColumns: (state, {columns, section} ) => {
    state[section].availableColumns = columns;
  },
  addTemporaryOrder(state, {columns, section}) {
    state[section].availableColumnsTemporary = columns;

  },
  checkColumn(state, {name, section}) {
    if (state[section].checkedColumnsForMenu.includes(name)) {
      state[section].checkedColumnsForMenu = state[section].checkedColumnsForMenu.filter(item => (item !== name))
    } else {
      state[section].checkedColumnsForMenu = [...state[section].checkedColumnsForMenu, name];
    }
  },
  columnsCancelPushed(state, section) {
    state[section].checkedColumnsForMenu = [...state[section].checkedColumnsForTable] 
  },
  columnsDonePushed(state, section) {
    state[section].checkedColumnsForTable = [...state[section].checkedColumnsForMenu]
  },
  changeColumnsOrder(state, section) {
    state[section].availableColumns = [...state[section].availableColumnsTemporary]
  },

  changeAvailableColumnsOrder(state, section) {
    let arr = state[section].availableColumns.filter( item => {
      return state[section].checkedColumnsForTable.includes(item.name)
    })
    state[section].checkedColumnsForTable = arr.map( item => item.name );
  },
  restoreDefaultCheckedColumns(state, section) {
    state[section].checkedColumnsForTable = [...state[section].checkedColumnsDefault];
    state[section].checkedColumnsForMenu = [...state[section].checkedColumnsDefault];
  },
  restoreInitialColumnsOrder(state, section) {
    state[section].availableColumns = [ ... state[section].availableColumnsDefault ]
    state[section].availableColumnsTemporary = [ ... state[section].availableColumnsDefault ]
  },
  isAdminUsersLoading (state, value) {
    state.users.isLoading = value
  },
  setDefaultCheckedColumns(state, {columns, section}) {
    state[section].checkedColumnsDefault = columns
  },
  addComplianceIssues (state, issues) {
    state.complianceIssues = issues
  },
  addUserAccountName (state, name) {
    console.log('----', state.users)
    state.users.accountName = name
  },
  addDefaultAvailableColumns (state, { columns, section }) {
    state[section].availableColumnsDefault = columns
  },
  addCurrentSection (state, section) {
    state.currentSection = section
  },
  addUserInfo (state, data) {
    console.log('ppp--ppp',state.users)
    state.users.userInfo = data
  },
  addSearchText (state, text) {
    state.users.search = text
  },
  addStatusesList(state, list) {
    state.plansMenuItem.statusesList = list
  },
  addUsersWithhAllStatuses(state, num) {
    state.plansMenuItem.usersWithAllStatuses = num
  },
  changePlanStatus(state, option) {
    state.plansMenuItem.selectedStatusOption = option
  },
  setAdminNotes(state, notes) {
    state.users.notes = notes
  }, 
  setAdminNoteText(state, text) {
    state.noteText = text
  },
  setSuspensionReasons(state, issues) {
    state.suspensionReasons = issues
  }
};

export default mutations;
