import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "../store/store";
import BCookie from "./../utils/BCookie";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: routes,
});

/**
 * Authentication check and return to login page before route change.
 */
router.beforeEach((to, from, next) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myref = urlParams.get('ref');
  const mylid = urlParams.get('lid');
  if(myref !== null && mylid !== null){
    var exdate = new Date();
    var exdays = 7;
    exdate.setDate(exdate.getDate() + exdays);
    // exdate.setTime(exdate.getTime() + (30 * 1000));
    var c_value = ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    console.log("ref=" + myref, "lid=" + mylid);
    document.cookie = "ref=" + myref;
    document.cookie = "lid=" + mylid;
  }
  console.log(myref, 'ref', mylid, 'lid', window.location.href);

  if (!store.getters.isAuthenticated) {
    console.log(to.meta.auth);
    if (to.meta.auth) {
      BCookie.set("redirect_path", window.location.pathname, 24 * 60 * 60);
      next({
        name: "Login1",
        params: { step: 1 },
      });
    } else {
      next();
    }
  } else {

    next();
    // if (store.getters.userInfo) {
    //   if (store.getters.userInfo.user_status === 1) {
    //     if (store.getters.userInfo.billing_status) {
    //       switch (store.getters.userInfo.billing_status) {
    //         case "Cancelled":
    //           //case 'Failed':
    //           store.commit("destroyAccessToken");
    //           BCookie.set(
    //             "redirect_path",
    //             window.location.pathname,
    //             24 * 60 * 60
    //           );
    //           if (to.name !== "Login" && to.name !== "Login1") {
    //             next({
    //               name: "Login1",
    //               params: { step: 1 },
    //             });
    //           }
    //           break;
    //         case "Inactive":
    //         case "Expired":
    //           if (to.name !== "Subscription" && to.meta.auth) {
    //             next({
    //               name: "Subscription",
    //             });
    //           }
    //           break;
    //       }
    //       if (store.getters.userInfo.billing_status) {
    //         switch (store.getters.userInfo.status_id) {
    //           case "5":
    //             next({
    //               name: "Payment Hold",
    //             });
    //             break;
    //             // case "2":
    //             // next({
    //             //   name: "Suspended",
    //             // });
    //             // break;
    //         }
    //       }
    //     } else {
    //       store.commit("destroyAccessToken");
    //       BCookie.set("redirect_path", window.location.pathname, 24 * 60 * 60);
    //       if (to.name !== "Login" && to.name !== "Login1") {
    //         next({
    //           name: "Login1",
    //           params: { step: 1 },
    //         });
    //       }
    //     }
    //   } else {
    //     // if(!store.state.userData.hasOwnProperty('billing_status')){
    //     // console.log(store.state.userData.hasOwnProperty('billing_status'))
    //     // console.log(to)
    //     // store.state.userData = null;
    //     // store.dispatch("destroyToken").then(response => {
    //     // 	window.location.replace("/login/1");
    //     // });
    //     // }
    //   }
    // }
    // var userInfo = store.getters ? store.getters.userInfo : false;
    // if (userInfo && userInfo.status_id) {
    //   if(userInfo.status_id == 5){
    //     next({
    //       name: "Payment Hold"
    //       });
    //   }
    // } else {
    //   store.dispatch("getUserData").then(r => {
    // 		if(r.data.status_id==5){
    // 			next({
    // 				name: "Payment Hold"
    // 			  });
    // 		}
    //   });
    // }
    // next();
  }
});

//====change page title after route changed.
router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - " + store.state.siteName;
    store.commit("changePageTitle", to.meta.title);
  }
});

export default router;
