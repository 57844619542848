// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// Todo: add later
// import './assets/css/video.css';
// Theme for elements
import "../theme/index.css";
import Vue from "vue";
import jQuery from 'jquery'
import ElementUI from "element-ui";
import App from "./App";
import router from "./router/index";
import axios from "axios";
import VueProgressBar from "vue-progressbar";
import VueTruncate from "vue-truncate-filter";
import VueLazyload from "vue-lazyload";
import VueDraggable from "vuedraggable";
import VueSweetAlert from "vue-sweetalert";
//import VueVideoPlayer from "vue-video-player";
import VueClipboard from "vue-clipboard2";
// import VueIntercom from "vue-intercom";
import locale from "element-ui/lib/locale/lang/en";
import VeeValidate from "vee-validate";
import VeeElement from "vee-element";
import store from "./store/store";
import VEvent from "./utils/VEvent";
import Ls from "./utils/Ls";
import BCookie from "./utils/BCookie";
import videojs from "video.js";
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import ToggleButton from 'vue-js-toggle-button'
import VueAxios from 'vue-axios';
import AdiloCookie from './utils/Cookie';
window.videojs = videojs;


Vue.prototype.stickyBaseScript = process.env.VUE_APP_sticky_BaseScript;
Vue.prototype.productionTip = process.env.VUE_APP_NODE_ENV === 'production';
Vue.prototype.backendUrl = process.env.VUE_APP_BACKEND_URL;
Vue.prototype.jQuery = jQuery
// ==========axios conf============



axios.interceptors.request.use(
    (requestConfig) => {
        if (!window.location.href.includes('stage-public') || requestConfig.url=='/user'){
            if (store.getters.isAuthenticated) {
                requestConfig.headers.Authorization = `Bearer ${store.state.accessToken}`;
            }
        }

        return requestConfig;
    },
    (requestError) => Promise.reject(requestError),
);

axios.interceptors.response.use(
    response => response,
    (error) => {
        if (!window.location.href.includes('stage-public')){
            if (error.response && error.response.status === 401 && error.config.url.indexOf('/api/login') < 0) {
                store.commit('destroyAccessToken');
                window.location.replace(`${window.location.origin}/login/1`);
            }else if(!error.status){
                console.log('Network Error!');
                // alert('Network Error!');
            }
        }

        return Promise.reject(error);
    }
);

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + 'api';
Vue.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.axios.defaults.headers.common['user-cookie'] = AdiloCookie;

// ==================================

Vue.use(ToggleButton);

Vue.mixin({
    data: function () {
        return {
            get backendUrl() {
                return process.env.VUE_APP_BACKEND_URL;
            },
            get stickyBaseScript() {
                return process.env.VUE_APP_sticky_BaseScript;
            }
        }
    },
    methods: {
        croppa(src, width, height, options) {
            try {
		// wasabi thumbnails
		if (src.includes('wasabisys.com') || src.includes('b-cdn.net') || src.includes('stream.adilo.com') || src.includes('audioThumbnail')) {
                    var size = 'large';
                    if (width <= 120)
			size = 'tiny';
                    else if (width <= 320)
			size = 'small';
                    else if (width <= 640)
			size = 'medium';
                    
                    return src.replace(/(.*).mp4-(.*)-(.*)/, '$1.mp4-' + size + '-$3');
		}
		// Build a croppa formatted URL
		// Defaults
		if (!src) return; // Don't allow empty strings
		if (!width) width = '_';
		else width = Math.round(width);
		if (!height) height = '_';
		else height = Math.round(height);
		
		// Produce the croppa syntax
		var suffix = '-' + width + 'x' + height;
		
		// Add options.  If the key has no arguments (like resize), the key will be like [1]
		if (options && options instanceof Array) {
                    var val, key;
                    for (key in options) {
			val = options[key];
			
			// A simple string option
			if (typeof val === 'string') suffix += '-' + val;
			
			// An object like {quadrant: 'T'} or {quadrant: ['T']}
			else if (typeof val === 'object') {
                            for (key in val) {
				val = val[key];
				if (val instanceof Array) suffix += '-' + key + '(' + val.join(',') + ')';
				else suffix += '-' + key + '(' + val + ')';
				break; // Only one key-val pair is allowed
                            }
			}
                    }
		}
		
		// Break the path apart and put back together again
		return src.replace(/^(.+)(\.[a-z]+)$/i, "$1" + suffix + "$2");
	    } catch (error) {return src;}
        }
    }
});

Vue.use(ElementUI, {locale});
Vue.use(VueProgressBar, {
    color: '#309CE3',
    failedColor: 'red',
    height: '2px'
});
Vue.use(VueTruncate);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'dist/error.png',
    loading: 'dist/loading.gif',
    attempt: 1
});
Vue.use(VeeValidate);
VeeValidate.Validator.extend('select_required_rule', {
    validate(value, [args]) {
        console.log('validate', args, value);
        if (value && value > 1)
            return true;
        return false;
    },
    getMessage(field) {
        return 'Validation failed'
    }
});
const rules = {};
const options = {};
const validator = new VeeValidate.Validator(rules, options);
Vue.use(VeeElement);
Vue.component('draggable', VueDraggable);
Vue.use(VueSweetAlert);
//Vue.use(VueVideoPlayer);
Vue.use(VueClipboard);
Vue.use(require('vue-moment'));
// Vue.use(VueIntercom, {appId: 'zmhhjfa6'});

Vue.filter('two_digits', (value) => {
    if (value.toString().length <= 1) {
        return '0' + value.toString()
    }
    return value.toString()
});

Vue.component('search-autocomplete', {
    functional: true,
    render: function (h, ctx) {
        var item = ctx.props.item;
        var icon = '';
        if (item.type === 'video') {
            icon = h('svg', {
                attrs: {
                    fill: '#000000',
                    height: '24',
                    width: '24',
                    viewBox: '0 0 24 24',
                    xmlns: 'http://www.w3.org/2000/svg',
                    class: 'icon'
                }
            }, [
                h('path', {attrs: {d: 'M0 0h24v24H0z', fill: 'none'}}),
                h('path', {attrs: {d: 'M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z'}})
            ]);
        }
        if (item.type === 'project') {
            icon = h('svg', {
                attrs: {
                    fill: '#000000',
                    height: '24',
                    width: '24',
                    viewBox: '0 0 24 24',
                    xmlns: 'http://www.w3.org/2000/svg',
                    class: 'icon'
                }
            }, [
                h('path', {attrs: {d: 'M0 0h24v24H0z', fill: 'none'}}),
                h('path', {attrs: {d: 'M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z'}})
            ]);
        }
        if (item.type === 'slate') {
            icon = h('svg', {
                attrs: {
                    fill: '#000000',
                    height: '24',
                    width: '24',
                    viewBox: '0 0 24 24',
                    xmlns: 'http://www.w3.org/2000/svg',
                    class: 'icon'
                }
            }, [
                h('path', {attrs: {d: 'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z'}}),
                h('path', {attrs: {d: 'M0 0h24v24H0z', fill: 'none'}})
            ]);
        }
        return h('li', ctx.data, [
            icon,
            h('div', {attrs: {class: 'result'}}, [
                h('div', {attrs: {class: 'value'}}, [
                    item.title
                ]),
                h('span', {attrs: {class: 'link'}}, [item.link])
            ])
        ]);
    },
    props: {
        item: {type: Object, required: true}
    }
});


// Global component register, automatically.
const requireComponent = require.context(
  './components', true, /Base[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
  )
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})
// Global component register, automatically.


/**
 * Vue Event Bus.
 * @type {VEvent}
 */
window.vEvent = new VEvent();

/**
 * Window Localstorage
 * @type {{set, get, remove}}
 */
window.Ls = Ls;

/**
 * Cookie
 * @type {{set, get, check, remove}}
 */
window.BCookie = BCookie;

/* eslint-disable no-new */
var vm = new Vue({
    el: '#app',
    router,
    store,
    axios,
    template: '<App/>',
    components: {App},
    render: h => h(App),
});

window.app = vm;
