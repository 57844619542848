<template>
  <div class="email-setting">
    
    <div>
      <div class="email-head d-flex">
        <h3 class="head-title mr-3">MandrillApp <span>(hello@4kplayer.com)</span></h3>
        <el-button type="primary" plain>Change Transactional Email Sender</el-button>
      </div>

      <p class="head-title mr-3">Email Header</p>

      <el-row>
        <el-col :span="12">
          <el-form-item label="From Name">
            <el-input class="expand field"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="From Email Address">
            <el-input class="expand field"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <p class="head-title mr-3">Email Footer (CAN SPAM)</p>

      <el-form-item label="Company Name:" class="d-flexx">
        <el-input class="expand field"></el-input>
      </el-form-item>

      <el-form-item label="Address:" class="d-flexx">
        <el-input class="expand field"></el-input>
      </el-form-item>

      <el-form-item label="City, Country" class="d-flexx">
        <el-input class="expand field"></el-input>
      </el-form-item>

      <p class="head-title tag">Tag List: %FNAME%, %LNAME%, %EMAIL%, %PLAN%</p>

      <div class="signup-box">
        <p class="head-title mr-3">On Signup</p>

        <el-form-item label="Subject" >
          <el-input></el-input>
        </el-form-item>

        <el-form-item label="Email Body" class="mb-0">
        </el-form-item>

        <el-form-item >
          <div class="email-body-header"></div>
          <el-input type="textarea" rows="15"></el-input>
        </el-form-item>

        <div class="aweber-info-buttons">
          <el-button type="info">cancel & close</el-button>
          <el-button type="primary">save & close</el-button>
        </div>

      </div>

      <div class="integration-desc">
        <ul>
          <li>On Change Of Plan</li>
          <li>On Cancellation</li>
          <li>On Account Suspension</li>
          <li>On Password Reset</li>
        </ul>
      </div>


    </div>
    
    </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },

};
</script>

<style lang="less">
.email-setting {
  left: 150px;
  position: absolute;
  width: calc(~"100% - 150px");
  height: calc(~"100vh - 120px");
  top:55px;
  padding: 30px;
  form {
    padding: 20px;
    background: #f9fbfb;
   > div{
     max-width: 1050px;
   }
  }
  .email-body-header{
    height: 70px;
    background: gray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .mb-0{margin-bottom:0}
  .el-textarea{
    textarea{
      border-top-left-radius:0;
      border-top-right-radius: 0;
    }
  }
  .d-flexx{
    .el-form-item__content{
      display:flex;
    }
    .el-form-item__label{
      min-width: 180px;
      text-align: left;
    }
  }
  .signup-box{
    background: #ebf9fa;
    padding: 15px 35px;
    border-radius: 6px;
    .el-form-item__label{
      text-transform: uppercase;
    }
  }
  h3{
    font-size: 20px;
    font-weight: bold;
    margin: 20px 25px 20px 0 ;
    color: #0c4077;
  }
  p.head-title{
    font-size: 16px;
    font-weight: bold;
    margin: 20px 25px 20px 0 ;
    color: #0c4077;
    &.tag{
      margin: 40px 25px 40px 0 ;
    }
    span{
      font-weight: normal;
    }
  }
  .integration-desc{
    margin-top: 30px;
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      max-width: 1200px;
      li{
        min-height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        background: #91c1c4;
        border-radius: 6px;
        margin-bottom: 20px;
        color: #fff;
        padding: 0 30px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .el-button--primary {
    color: #fff;
    background-color: #0a44b1;
    border-color: #0a44b1;
  }
  .el-button--info {
    color: #fff;
    background-color: #a2adad;
    border-color: #a2adad;
  }
  .aweber-info-buttons{
    display: flex;
    justify-content: flex-end;
    margin-top:40px;
  }
  .email-head{
    align-items: center;
    width: 100%;
  }
  .el-button--primary.is-plain{
    background: #ebf9fa;
    border-color: #33acdb;
    border-radius: 40px;
    color: #406e97;
    height: 45px;
  }
  .expand.field{
    input{
      max-width: 400px;
    }
  }
}
</style>
