<template>
  <div id="app" v-cloak> 
    <app-header
      v-if="!isAdmin() && isLoggedIn && userInfo"
      :user="userInfo"
      @openSearch="handleSearchOpen"
    ></app-header>
    <router-view :user="userInfo" :class="{'failed_user':userInfo && userInfo.billing_status=='Failed'}" ref="routerView"></router-view>
    <vue-progress-bar></vue-progress-bar>
    <app-search v-if="isLoggedIn && userInfo && userActive" ref="appSearch"></app-search>
<!--    <modal-video v-if='isLoggedIn && userInfo && userActive'/>-->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AppHeader from "./components/App/AppHeader.vue";
import AppSearch from "./components/App/AppSearch.vue";
import ModalVideo from './components/Video/ModalVideo';

export default {
  name: "app",
  components: {
    AppHeader,
    AppSearch,
    ModalVideo,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isAuthenticated"
    }),
  
    user: {
      get() {
        return this.$store.state.userData;
      }
    }
  },
  data: () => {
    return {
      userId: 0,
      name: "",
      email: "",
      currentTeam: 0,
      userInfo: null,
      userActive: false,
      blockedRoutes: ['RoomPage', 'SnapSharedRecording', 'SnapPageView'],
    };
  },
  beforeDestroy() {
    window.vEvent.stop("update-user-data", this.updateUserInfo);
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getUserDetail();
    }

    this.eventListen();
  },
  methods: {
    ...mapMutations({
      setUserInfo: "setUserInfo"
    }),

    /**
     * Components Events Listen.
     */
    eventListen() {
      window.vEvent.listen("update-user-data", this.updateUserInfo);
      window.Ls.remove("sub-user-info");
    },

    async getUserDetail() {
      var userInfo = this.$store && this.$store.getters && this.$store.getters.userInfo ? this.$store.getters.userInfo : false;
      if (userInfo && userInfo.status_id) {
        if (userInfo.status_id == 5) {
          this.$router.push('/payment_hold');
        } else {
          this.$nextTick(() => {
            this.setUserData(this.user);
            this.setUserActive();
          });
        }
      } else {

        await this.$store
        .dispatch("getUserData")
        .then(r => {
          if(this.user.status_id==5){
            this.$router.push('/payment_hold')
          }else{
          this.$nextTick(() => {
            this.setUserData(this.user);
            this.setUserActive();
          });
          }
        })
        .catch(e => {
          this.$store.commit("destroyAccessToken");
          this.$router.push("/login/1");
        });
      }

    },

    handleSearchOpen() {
      this.$refs.appSearch.handleOpen();
    },
    setUserActive() {
      this.userActive = true;
      // this.userActive = false;{{this.userInfo}}
      // if (this.userInfo.user_status === 1) {
      //   if (this.userInfo.billing_status) {
      //     switch (this.userInfo.billing_status) {
      //       case "Active":
      //       case "Trial":
      //       case "VerifyRequired":
      //       case "Failed":
      //       case "Cancelled":
      //         this.userActive = true;
      //         break;
      //       case "Expired":
      //       case "Inactive":
      //       // case "Cancelled":
      //         // case 'Failed':
      //         this.userActive = false;
      //         break;
      //     }
      //   }
      // } else {
      //   this.userActive = false;
      // }
    },
    setUserData(data) {
      if (data) {
        this.userInfo = data;
        this.userId = this.userInfo.id;
        this.name = this.userInfo.first_name;
        this.email = this.userInfo.email;

        // this.readyInterCom();
      }
    },
    updateUserInfo(user) {
      this.setUserInfo(user);
    },
    readyInterCom() {
      this.$intercom.boot({
        user_id: this.userId,
        name: this.user.first_name,
        email: this.user.email
      });
    },
    loadKoyaku()
    {
      // (function(d,a){function c(){var b=d.createElement("script");b.async=!0;b.type="text/javascript";b.src=a._settings.messengerUrl;b.crossOrigin="anonymous";var c=d.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}window.kayako=a;a.readyQueue=[];a.newEmbedCode=!0;a.ready=function(b){a.readyQueue.push(b)};a._settings={apiUrl:"https://bigcommand-help.kayako.com/api/v1",messengerUrl:"https://bigcommand-help.kayakocdn.com/messenger",realtimeUrl:"wss://kre.kayako.net/socket"};window.attachEvent?window.attachEvent("onload",c):window.addEventListener("load",c,!1)})(document,window.kayako||{});
    },
    isAdmin() {
      // console.log(this.user)
      return window.location.pathname.includes("admin");
    },
  },
  watch: {
    "user.email": function(email) {
      if (this.isLoggedIn) {
        // this.$intercom.update({
        //   email
        // });
      }
    },
    user(newUser, oldUser) {
      this.userInfo = false;
      this.userActive = false;
      if (this.isLoggedIn) {
        if (newUser) {
          this.setUserData(newUser);
          this.setUserActive();
          let vm = this;
          this.$nextTick(function() {
            if (!oldUser || newUser !== oldUser) {
              vm.$forceUpdate();
            }
          });
        }
      }
    },
    $route(to, from)
    {
      if (!this.blockedRoutes.includes(to.name)) this.loadKoyaku();
    }
  }
};
</script>
<style lang="less">
@import "assets/less/bootstrap/bootstrap";
/*@import "assets/less/bootstrap-select/bootstrap-select";*/
@import "assets/less/variables";
/*@import "assets/less/spacing";*/
/*@import "assets/less/form";*/
/*@import "assets/less/text";*/
/*@import "assets/less/general-hacks";*/
/*@import "assets/less/sweetalert";*/
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');*/
@import "~@fortawesome/fontawesome-free/less/solid.less";
@import "~@fortawesome/fontawesome-free/less/regular.less";
@import "~@fortawesome/fontawesome-free/less/brands.less";
@import "~@fortawesome/fontawesome-free/less/fontawesome.less";
@import "assets/less/layout";
@import "assets/less/vars";
@import "assets/less/app";

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

[v-cloak] * {
  display: none;
}
</style>

<style>

@media screen and (max-width: 991px) {
  .mobile-col-lg-12 {
    width: 100%;
  }

  .mobile-border-right-none-md-12 {
    border-right: none;
  }

  .mobile-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .mobile-col-md-12 {
    width: 100%;
  }

  .mobile-col-md-6 {
    width: 50%;
  }

  .mobile-border-right-none-md-12 {
    border-right: none !important;
  }
}

@media screen and (max-width: 575px) {
  .mobile-col-sm-12 {
    width: 100%;
  }

  .mobile-w-100-sm {
    width: 100% !important;
  }

  .mobile-px-2-sm {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>
