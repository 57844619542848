import { render, staticRenderFns } from "./WhiteBoardSettingsIntegration.vue?vue&type=template&id=68dae8c0&scoped=true"
import script from "./WhiteBoardSettingsIntegration.vue?vue&type=script&lang=js"
export * from "./WhiteBoardSettingsIntegration.vue?vue&type=script&lang=js"
import style0 from "./WhiteBoardSettingsIntegration.vue?vue&type=style&index=0&id=68dae8c0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68dae8c0",
  null
  
)

export default component.exports