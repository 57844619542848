<template>
    <div class="users-main">
        <div class="user-header">
            <div class="left-side">
                <div class="title">
                    Create New Plan
                </div>
            </div>

        </div>


        <div class="relative mb-3">
            <el-row :gutter="40" class="m-top-3">
                <el-col :span="8" :xs="24">

            <div class="branding-logo-position">
                <div class="positions title">
                    <span class="textUppercase">Plan Name</span>
                </div>
                <div class="positions">
                    <input
                            class="form-control with_arrow_190"
                            id="user-first-name"
                            placeholder="Enter first name"
                            type="text"
                            v-model="plan_name"
                    />

                </div>
                <span class="error" v-if="validation.type === 'plan_name'">
                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        {{validation.message}}</span>
            </div>


            <div class="branding-logo-position">
                <div class="positions title">
                    <span class="textUppercase">Plan Type</span>
                </div>
                <div class="positions">

                    <el-row>
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="1" v-model="plan_type" :label="'free'" @click="plan_type = 'free'">Free
                            </el-radio>
                        </el-col>
                    </el-row>

                    <el-row class="m-top-2">
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="2" v-model="plan_type" :label="'paid'" @click="plan_type = 'paid'">Paid
                            </el-radio>
                        </el-col>
                    </el-row>

                </div>
            </div>
            <div class="branding-logo-position">
                <div class="positions title">
                    <span class="textUppercase">Recurring</span>
                </div>
                <div class="positions">

                    <el-row>
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="1" v-model="recurring" :label="'never'" @click="recurring = 'never'">
                                Never
                            </el-radio>
                        </el-col>
                    </el-row>

                    <el-row class="m-top-2">
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="2" v-model="recurring" :label="'monthly'" @click="recurring = 'monthly'">
                                Monthly
                            </el-radio>
                        </el-col>
                    </el-row>

                    <el-row class="m-top-2">
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="2" v-model="recurring" :label="'annually'" @click="recurring = 'annually'">
                                Annually
                            </el-radio>
                        </el-col>
                    </el-row>

                </div>
            </div>
            <div class="branding-logo-position">
                <div class="positions title">
                    <span class="textUppercase">Free Trial</span>
                </div>
                <div class="positions">

                    <el-row>
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="1" v-model="free_trial" :label="'none'" @click="free_trial = 'none'">None
                            </el-radio>
                        </el-col>
                    </el-row>

                    <el-row class="m-top-2">
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="2" v-model="free_trial" :label="'7_days_free'"
                                      @click="free_trial = '7_days_free'">7 Days Free
                            </el-radio>
                        </el-col>
                    </el-row>

                    <el-row class="m-top-2">
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="2" v-model="free_trial" :label="'14_days_free'"
                                      @click="free_trial = '14_days_free'">14 Days Free
                            </el-radio>
                        </el-col>
                    </el-row>

                    <el-row class="m-top-2">
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="10" :xs="23">
                            <el-radio label="2" v-model="free_trial" :label="'30_days_free'"
                                      @click="free_trial = '30_days_free'">30 Days Free
                            </el-radio>
                        </el-col>
                    </el-row>

                </div>
            </div>


            <div class="branding-logo-position">
                <div class="positions title">
                    <span class="textUppercase">FEE (US $)</span>
                </div>
                <div class="positions">
                    <input
                            class="form-control with_arrow_190"
                            id="user-last-name"
                            placeholder="Enter first name"
                            type="text"
                            v-model="fees"
                    />

                </div>
                <span class="error" v-if="validation.type === 'fees'">
                         <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        {{validation.message}}</span>
            </div>
                </el-col>
            </el-row>
        </div>
        <el-button
                type="custom"
                class="button__cancle"
                @click="$router.push('/white-label/plans')"
        >Cancel
        </el-button>
        <el-button
                type="custom"
                class="button__upload" @click="savePlan()"
        > Create
            Plan
        </el-button>

    </div>
</template>


<script>
    import SearchQuery from "../../components/Project/AllProjects/Components/SearchQuery";
    import AuthEmailField from "../Auth/Items/AuthEmailField.vue";
    import PasswordChecker from "./PasswordChecker.vue";

    export default {
        name: 'WhiteBoardCreatePlans',
        components: {},
        data() {
            return {
                plan_name: '',
                fees: '0',
                plan_type: 'paid',
                recurring: 'monthly',
                free_trial: '7_days_free',

                validation: {
                    message: '',
                    type: '',
                }

            };
        },
        computed: {},
        methods: {

            savePlan() {

                if (this.plan_name === '') {
                    this.validation.message = 'Please enter plan name';
                    this.validation.type = 'plan_name';

                    return false;
                }else if (this.fees === '0') {
                    this.validation.message = 'Please enter fees';
                    this.validation.type = 'fees';

                    return false;
                }else{
                    this.validation.message = '';
                    this.validation.type = '';
                }

                let post_data = {
                    url: "/whitelabel/plan/store",
                    data: {
                        plan_name: this.plan_name,
                        fees: this.fees,
                        plan_type: this.plan_type,
                        recurring: this.recurring,
                        free_trial: this.free_trial,
                    }
                };


                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        if (response.data.result === "success") {
                            // $router.push('/white-label/users')
                            this.$router.push({name: 'WhiteBoardPlans'})
                        }
                    })
                    .catch(error => {

                    });
            }
        },

    };
</script>

<style lang="less">
    .error{
        color: red;
    }
    .button__cancle{
        padding: 10px 25px 9px;
        background-color: #fff;
        border: 1px solid #0044b1;
        font-size: 16px;
        line-height: 19px;
        color: #0044b1;
        border-radius: 5px;
    }
    .button__upload {
        padding: 10px 25px 9px;
        background-color: #0044b1;
        border: none;
        font-size: 16px;
        line-height: 19px;
        color: white;
        border-radius: 5px;
    }
    .users-main {
        padding: 40px;
        overflow: auto;
        top: 55px;
        position: absolute;
        width: 100%;
    }

    .user-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .left-side {
            .title {
                font-weight: bold;
                font-size: 22px;
            }

            .total {
                margin-left: 15px;
                font-weight: normal;
            }
        }

        .el-button--custom {
            font-weight: bolder;
        }

        .right-side {
            display: flex;
            align-items: center;

            .text-uppercase {
                font-weight: 700;
            }

            div {
                margin-right: 20px;
            }

            .button__upload {
                padding: 10px 25px 9px;
                background-color: #0044b1;
                border: none;
                font-size: 16px;
                line-height: 19px;
                color: white;
                border-radius: 5px;
            }
        }


    }

    .branding-logo-position {
        margin-top: 30px;
        .positions{
            display: flex;
            margin-top: 15px;
            .el-row{
                margin-right: 15px;
            }
        }
    }

    .user-list-table {
        min-width: 900px;

        p {
            margin-bottom: 5px;
        }

        .table-header {
            display: flex;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .contact-row {
            width: 19%;

            p.name {
                font-weight: bold;
            }

            p {
                margin-bottom: 5px;
                color: #2c3e50;
            }
        }

        .status-row {
            width: 10%
        }

        .plan-row {
            width: 10%;
        }

        .joined-row {
            width: 10%;
        }

        .last-activity-row {
            width: 17%;
        }

        .asset-row {
            width: 17%;
        }

        .table-body {
            .plan-row, .joined-row, .last-activity-row, .asset-row {
                color: #485050cf;
            }
        }

        .action-row {
            width: 17%;
            display: inline-grid;

            button {
                background: none;
                border: 0px;
                text-align: left;
            }
        }

        .table-row {
            display: flex;
            padding-bottom: 30px;

            .actions {
                display: block;
            }
        }
    }

    .color-red {
        color: #d93636;
    }

    .color-pink {
        color: #ea00d8;
    }

    .color-green {
        color: #0bd603;
    }

    .color-blue {
        color: #0bacdb;
    }

    .textUppercase {
        text-transform: uppercase;
    }
</style>

