<template>
    <div class="integration-setting">
        <p class="head-title">Configure Your Integration</p>

        <div class="integration-desc">
            <ul>
                <li @click="storeIntegrationDetail('aweber')">Aweber</li>
            </ul>
        </div>
        <div class="aweber-info" v-if="type === 'aweber'">
            <p>Aweber</p>
            <div class="form-group">
                <label>API KEY</label>
                <el-input v-model="aweber.api_key"></el-input>
                <span class="error" v-if="aweber.msg !== '' && aweber.type === 'key'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{aweber.msg}}</span>
            </div>

            <div class="form-group">
                <label>API SECRET</label>
                <el-input v-model="aweber.api_secret"></el-input>
                <span class="error" v-if="aweber.msg !== '' && aweber.type === 'secret'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{aweber.msg}}</span>
            </div>

            <div class="aweber-info-buttons">
                <el-button type="info" @click="storeIntegrationDetail">cancel & close</el-button>
                <el-button type="primary" @click="storeIntegration('aweber')">save & close</el-button>
            </div>
        </div>


        <div class="integration-desc">
            <ul>
                <li @click="storeIntegrationDetail('mailchimp')">MailChimp</li>
            </ul>
        </div>
        <div class="aweber-info" v-if="type === 'mailchimp'">
            <p>MailChimp</p>
            <div class="form-group">
                <label>API KEY</label>
                <el-input v-model="mailchimp.api_key"></el-input>
                <span class="error" v-if="mailchimp.msg !== '' && mailchimp.type === 'key'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{mailchimp.msg}}</span>
            </div>

            <div class="form-group">
                <label>API SECRET</label>
                <el-input v-model="mailchimp.api_secret"></el-input>
                <span class="error" v-if="mailchimp.msg !== '' && mailchimp.type === 'secret'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{mailchimp.msg}}</span>
            </div>

            <div class="aweber-info-buttons">
                <el-button type="info" @click="storeIntegrationDetail">cancel & close</el-button>
                <el-button type="primary" @click="storeIntegration('mailchimp')">save & close</el-button>
            </div>
        </div>


        <div class="integration-desc">
            <ul>
                <li @click="storeIntegrationDetail('convertkit')">ConvertKit</li>
            </ul>
        </div>
        <div class="aweber-info" v-if="type === 'convertkit'">
            <p>ConvertKit</p>
            <div class="form-group">
                <label>API KEY</label>
                <el-input v-model="convertkit.api_key"></el-input>
                <span class="error" v-if="convertkit.msg !== '' && convertkit.type === 'key'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{convertkit.msg}}</span>
            </div>

            <div class="form-group">
                <label>API SECRET</label>
                <el-input v-model="convertkit.api_secret"></el-input>
                <span class="error" v-if="convertkit.msg !== '' && convertkit.type === 'secret'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{convertkit.msg}}</span>
            </div>

            <div class="aweber-info-buttons">
                <el-button type="info" @click="storeIntegrationDetail">cancel & close</el-button>
                <el-button type="primary" @click="storeIntegration('convertkit')">save & close</el-button>
            </div>
        </div>


        <div class="integration-desc">
            <ul>
                <li @click="storeIntegrationDetail('getresponse')">GetResponse</li>
            </ul>
        </div>
        <div class="aweber-info" v-if="type === 'getresponse'">
            <p>GetResponse</p>
            <div class="form-group">
                <label>API KEY</label>
                <el-input v-model="getresponse.api_key"></el-input>
                <span class="error" v-if="getresponse.msg !== '' && getresponse.type === 'key'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{getresponse.msg}}</span>
            </div>

            <div class="form-group">
                <label>API SECRET</label>
                <el-input v-model="getresponse.api_secret"></el-input>
                <span class="error" v-if="getresponse.msg !== '' && getresponse.type === 'secret'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{getresponse.msg}}</span>
            </div>

            <div class="aweber-info-buttons">
                <el-button type="info" @click="storeIntegrationDetail">cancel & close</el-button>
                <el-button type="primary" @click="storeIntegration('getresponse')">save & close</el-button>
            </div>
        </div>


        <div class="integration-desc">
            <ul>
                <li @click="storeIntegrationDetail('activecampaign')"> ActiveCampaign</li>
            </ul>
        </div>
        <div class="aweber-info" v-if="type === 'activecampaign'">
            <p>ActiveCampaign</p>
            <div class="form-group">
                <label>API KEY</label>
                <el-input v-model="activecampaign.api_key"></el-input>
                <span class="error" v-if="activecampaign.msg !== '' && activecampaign.type === 'key'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{activecampaign.msg}}</span>
            </div>

            <div class="form-group">
                <label>API SECRET</label>
                <el-input v-model="activecampaign.api_secret"></el-input>
                <span class="error" v-if="activecampaign.msg !== '' && activecampaign.type === 'secret'">
                     <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{activecampaign.msg}}</span>
            </div>

            <div class="aweber-info-buttons">
                <el-button type="info" @click="storeIntegrationDetail">cancel & close</el-button>
                <el-button type="primary" @click="storeIntegration('activecampaign')">save & close</el-button>
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                aweber: {
                    api_key: "",
                    api_secret: "",
                    msg: '',
                    type: '',

                },

                activecampaign: {
                    api_key: "",
                    api_secret: "",
                    msg: '',
                    type: '',
                },

                getresponse: {
                    api_key: "",
                    api_secret: "",
                    msg: '',
                    type: '',
                },

                convertkit: {
                    api_key: "",
                    api_secret: "",
                    msg: '',
                    type: '',
                },

                mailchimp: {
                    api_key: "",
                    api_secret: "",
                    msg: '',
                    type: '',
                },

                type: 'aweber'
            };
        },
        created(){
            this.getIntegration()
        },
        methods: {
            storeIntegrationDetail(integration = '') {
                this.type = integration
            },

            getIntegration() {

                let post_data = {
                    url: "/whitelabel/integration/list",

                };

                var $this = this;
                this.$store.dispatch("post", {...post_data})
                    .then(response => {

                        if (response.data.integration) {

                            response.data.integration.forEach(function (ele, index) {
                                if (ele.name === 'aweber') {
                                    $this.aweber.api_key = ele.api_key
                                    $this.aweber.api_secret = ele.api_secret
                                }

                                if (ele.name === 'activecampaign') {
                                    $this.activecampaign.api_key = ele.api_key
                                    $this.activecampaign.api_secret = ele.api_secret

                                }


                                if (ele.name === 'getresponse') {
                                    $this.getresponse.api_key = ele.api_key
                                    $this.getresponse.api_secret = ele.api_secret
                                }

                                if (ele.name === 'convertkit') {
                                    $this.convertkit.api_key = ele.api_key
                                    $this.convertkit.api_secret = ele.api_secret
                                }

                                if (ele.name === 'mailchimp') {
                                    $this.mailchimp.api_key = ele.api_key
                                    $this.mailchimp.api_secret = ele.api_secret
                                }
                            })

                        }


                    })
                    .catch(error => {

                    });
            },
            storeIntegration(name) {
                var api_key = '';
                var api_secret = '';


                if (name === 'aweber') {
                    api_key = this.aweber.api_key
                    api_secret = this.aweber.api_secret

                    if (this.aweber.api_key === '') {
                        this.aweber.msg = 'Please enter api key'
                        this.aweber.type = 'key'
                        return false;
                    } else if (this.aweber.api_secret === '') {
                        this.aweber.msg = 'Please enter api secret'
                        this.aweber.type = 'secret'
                        return false;
                    } else {
                        this.aweber.msg = ''
                        this.aweber.type = ''
                    }
                }

                if (name === 'activecampaign') {
                    api_key = this.activecampaign.api_key
                    api_secret = this.activecampaign.api_secret


                    if (this.activecampaign.api_key === '') {
                        this.activecampaign.msg = 'Please enter api key'
                        this.activecampaign.type = 'key'
                        return false;
                    } else if (this.activecampaign.api_secret === '') {
                        this.activecampaign.msg = 'Please enter api secret'
                        this.activecampaign.type = 'secret'
                        return false;
                    } else {
                        this.activecampaign.msg = ''
                        this.activecampaign.type = ''
                    }
                }

                if (name === 'getresponse') {
                    api_key = this.getresponse.api_key
                    api_secret = this.getresponse.api_secret

                    if (this.getresponse.api_key === '') {
                        this.getresponse.msg = 'Please enter api key'
                        this.getresponse.type = 'key'
                        return false;
                    } else if (this.getresponse.api_secret === '') {
                        this.getresponse.msg = 'Please enter api secret'
                        this.getresponse.type = 'secret'
                        return false;
                    } else {
                        this.getresponse.msg = ''
                        this.getresponse.type = ''
                    }
                }

                if (name === 'convertkit') {
                    api_key = this.convertkit.api_key
                    api_secret = this.convertkit.api_secret

                    if (this.convertkit.api_key === '') {
                        this.convertkit.msg = 'Please enter api key'
                        this.convertkit.type = 'key'
                        return false;
                    } else if (this.convertkit.api_secret === '') {
                        this.convertkit.msg = 'Please enter api secret'
                        this.convertkit.type = 'secret'
                        return false;
                    } else {
                        this.convertkit.msg = ''
                        this.convertkit.type = ''
                    }

                }

                if (name === 'mailchimp') {
                    api_key = this.mailchimp.api_key
                    api_secret = this.mailchimp.api_secret

                    if (this.mailchimp.api_key === '') {
                        this.mailchimp.msg = 'Please enter api key'
                        this.mailchimp.type = 'key'
                        return false;
                    } else if (this.mailchimp.api_secret === '') {
                        this.mailchimp.msg = 'Please enter api secret'
                        this.mailchimp.type = 'secret'
                        return false;
                    } else {
                        this.mailchimp.msg = ''
                        this.mailchimp.type = ''
                    }
                }


                let post_data = {
                    url: "/whitelabel/integration/store",
                    data: {
                        name: name,
                        api_key: api_key,
                        api_secret: api_secret,

                    }
                };
                var $this = this;

                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        $this.$message({
                            showClose: true,
                            message: 'Detail successfully saved',
                            type: 'success'
                        });
                    })
                    .catch(error => {

                    });
            }
        },
    };
</script>

<style lang="less" scoped>
    .integration-setting {
        left: 150px;
        position: absolute;
        width: calc(~"100% - 150px");
        height: calc(~"100vh - 120px");
        top: 55px;
        padding: 30px;

        form {
            padding: 20px;
            background: #f9fbfb;
        }

        .aweber-info {
            background: #ebf9fa;
            padding: 30px 25px;
            border-radius: 4px;
            max-width: 1200px;

            p {
                font-size: 18px;
                font-weight: bold;
                color: #0c4077;
            }
        }

        .el-form-item__label {
            color: #0c4077;
        }

        p.head-title {
            font-size: 18px;
            font-weight: bold;
            margin: 20px 0 20px;
            color: #0c4077;
        }

        .aweber-info-buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 40px;
        }

        .el-button--primary {
            color: #fff;
            background-color: #0a44b1;
            border-color: #0a44b1;
        }

        .el-button--info {
            color: #fff;
            background-color: #a2adad;
            border-color: #a2adad;
        }

        .integration-desc {
            margin-top: 30px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                max-width: 1200px;

                li {
                    min-height: 80px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    background: #91c1c4;
                    border-radius: 6px;
                    margin-bottom: 20px;
                    color: #fff;
                    padding: 0 30px;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }
</style>
